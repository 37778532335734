export default {
  'CoreTechnology.index.486489-0': 'Resistance to chemical hazards such as acid, alkali, and oxidation',
  'CoreTechnology.index.486489-1': 'Resistant to organic solvents and oil pollution',
  'CoreTechnology.index.486489-2': 'Resistant to pollution such as suspended solids and turbidity',
  'CoreTechnology.index.486489-3': 'High temperature resistance',
  'CoreTechnology.index.486489-4': 'High mechanic strength',
  'CoreTechnology.index.486489-5': 'Environmentally friendly and recyclable',
  'CoreTechnology.index.486489-6': 'High flux',
  'CoreTechnology.index.486489-7': '3-4 times that of organic membranes',
  'CoreTechnology.index.486489-8': 'Small footprint',
  'CoreTechnology.index.486489-9': 'Low cost',
  'CoreTechnology.index.486489-10': 'Own technology and production',
  'CoreTechnology.index.486489-11': 'Long lifespan',
  'CoreTechnology.index.486489-12': 'Low lifecycle cost',
  'CoreTechnology.index.486489-13': 'Good production water quality',
  'CoreTechnology.index.486489-14': 'Good uniformity of pore size distribution in inorganic membranes',
  'CoreTechnology.index.486489-15': 'Low energy consumption',
  'CoreTechnology.index.486489-16': 'Energy saving, low transmembrane pressure difference',
  'CoreTechnology.index.486489-17': "It's 1/2-1/3 of the organic membrane",
  'CoreTechnology.index.486489-18': 'Twice increase in membrane area',
  'CoreTechnology.index.486489-19':
    'Compared to conventional flat membrane, Panda W inorganic membrane increases the membrane area by two times at similar loading densities',
  'CoreTechnology.index.486489-20': 'Backwashing is more efficient and thorough',
  'CoreTechnology.index.486489-21':
    'The inner channel of the W inorganic membrane is a diamond shaped structure, and the thickness of the membrane is equal at each point, making backwashing more efficient and thorough',
  'CoreTechnology.index.486489-22': 'Pure water flux reaches 1.5-2 times',
  'CoreTechnology.index.486489-23':
    'The support of W inorganic membrane is aluminum oxide, and the membrane layer can be made of various materials such as aluminum oxide, zirconia, and silicon carbide. The hydrophilicity of the silicon carbide film developed by Panda is significantly better than that of aluminum oxide, and the pure water flux of the silicon carbide film layer is 1.5-3 times that of the aluminum oxide film layer',
  'CoreTechnology.index.486489-24': '20% -30% higher flexural strength',
  'CoreTechnology.index.486489-25':
    'The longitudinal bending strength of W inorganic membrane is 20%~30% higher than that of conventional flat ceramic membrane',
  'CoreTechnology.index.486489-26': 'Core Technology ',
  'CoreTechnology.index.486489-27': 'W inorganic membrane',
  'CoreTechnology.index.486489-28': 'Compared to traditional organic membranes',
  'CoreTechnology.index.486489-29': 'Compared to conventional flat ceramic films',
  'CoreTechnology.index.486489-30': '/en/coreTechnology',
};
