/*
 * 移动端路由
 */
import { lazy } from 'react';
import lazyLoad from '@c/components/lozyLoad';
import { MenuRoute } from '@c/types/router';
import pageRouter from './pageRouter';
const Layout = lazyLoad(lazy(() => import(`@m/components/layout/index`)));

const pcRouter: MenuRoute[] = [
  {
    path: 'en',
    name: 'layout',
    key: 'layout',
    element: Layout,
    isSubMenu: false,
    children: [...pageRouter],
  },
  {
    path: '',
    name: 'layout',
    key: 'layout',
    element: Layout,
    isSubMenu: false,
    children: [...pageRouter],
  },
];
export default pcRouter;
