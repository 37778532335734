// 处理工艺组件
import React from 'react';
import styles from './index.less';
import { useTranslation } from 'react-i18next';

const TreatmentProcess = (props: any) => {
  const { list } = props;
  const { t } = useTranslation();

  return (
    <div className={styles['treatment-process']}>
      <div className={styles['treatment-process-title']}>{t('TreatmentProcess.index.902955-0')}</div>
      {list && list.length
        ? list.map((item: any, index) => (
            <div className={styles['process-box']} key={index}>
              {item.title ? (
                <>
                  <div className={styles['process-title']}>{item.title}</div>
                  <div className={styles['process-line']}>
                    <div className={styles['process-dian']} />
                  </div>
                </>
              ) : null}
              <div className={styles['process-content']}>
                <img className={styles['img1']} src={item.imageSrc} alt="" />
              </div>
            </div>
          ))
        : null}
    </div>
  );
};
export default TreatmentProcess;
