import React from 'react';
import { Button, Result } from 'antd';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: '' };
  }

  static getDerivedStateFromError(error) {
    console.log('错误信息：', error);
    return { hasError: true, errorInfo: error.message };
  }
  render() {
    const isDev = process.env.NODE_ENV === 'development';
    if (this.state.hasError) {
      return (
        <Result
          title={'访问发生异常'}
          style={{
            height: '100%',
            background: '#fff',
          }}
          icon={
            <img
              src={'https://gw.alipayobjects.com/zos/antfincdn/zIgkN%26mpMZ/shibaizhuangtaizuo.png'}
              style={{ width: '256px' }}
            ></img>
          }
          extra={
            <>
              {isDev && (
                <div
                  style={{
                    maxWidth: 620,
                    textAlign: 'left',
                    backgroundColor: 'rgba(255,229,100,0.3)',
                    borderLeftColor: '#ffe564',
                    borderLeftWidth: '9px',
                    borderLeftStyle: 'solid',
                    padding: '20px 45px 20px 26px',
                    margin: 'auto',
                    marginBottom: '30px',
                    marginTop: '20px',
                  }}
                >
                  <p>异常捕获：{this.state.errorInfo}</p>
                </div>
              )}

              <Button
                danger={true}
                type={'primary'}
                onClick={() => {
                  window.location.reload();
                }}
              >
                刷新页面
              </Button>
            </>
          }
        />
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
