import { action, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { ReactNode } from 'react';
import { DEFAULT_KEY } from '@c/utils/consts';

interface PanesInterFace {
  title: string;
  content: ReactNode;
  key: string;
  path: string;
  closable: boolean;
}
class TabStore {
  @observable paneList: Array<PanesInterFace> = [];
  @observable defaultActiveKey: string = DEFAULT_KEY;

  @action.bound
  updatePanes(List: Array<PanesInterFace>) {
    // const result = _.unionBy(List, this.paneList, 'key');
    this.paneList = [...List];
  }

  @action.bound
  updateDefaultActiveKey(key: string) {
    this.defaultActiveKey = key;
  }

  constructor() {
    makeObservable(this);
    makePersistable(this, {
      name: 'TabStore',
      properties: ['paneList', 'defaultActiveKey'],
      storage: sessionStorage,
    });
  }
}

export default new TabStore();
