export default {
  'DomesticWater.index.358859-0': 'Water Plant Water Production',
  'DomesticWater.index.358859-1': 'Secondary Water Supply',
  'DomesticWater.index.358859-2': 'Advanced Treatment · Water Quality Compliance',
  'DomesticWater.index.358859-3': 'Suitable for the construction, upgrading, and renovation of water plants',
  'DomesticWater.index.358859-4': 'Ultrafiltration purification · high-quality drinks',
  'DomesticWater.index.358859-5': 'Suitable for new/old residential and industrial parks',
  'DomesticWater.index.358859-6': 'Domestic Water',

  'IndustrialSeparation.index.938303-0': 'Source (material liquid) → Product (product)',
  'IndustrialSeparation.index.938303-1':
    'In the production process of high-quality soy sauce, traditional filtration methods generally have disadvantages such as low clarity, high temperature sterilization after filtration, and easy backmixing. The use of membrane filtration can not only effectively remove microorganisms, but also maximize the retention of soy sauce flavor. The filtered soy sauce will not precipitate after long-term storage.',
  'IndustrialSeparation.index.938303-2':
    'Using ceramic membrane technology to filter vinegar and cooking wine can thoroughly clarify rice vinegar and cooking wine products, significantly alleviate the sedimentation problem during the shelf life of aged vinegar, effectively remove microorganisms in vinegar and cooking wine, improve product quality, and simplify the process. The unique cross flow filtration method and the material characteristics of inorganic membranes make it superior to traditional filtration methods in terms of high filtration accuracy, zero addition during the filtration process, no impact on product flavor, filter element compliance with food safety, and energy conservation.',
  'IndustrialSeparation.index.938303-3': 'Source (material liquid) → Product (a certain traditional Chinese medicine)',
  'IndustrialSeparation.index.938303-4':
    'The complex chemical composition of traditional Chinese medicine leads to complex extraction and separation processes, low separation efficiency, high cost, serious environmental pollution, and high labor intensity in the production process of traditional Chinese medicine.',
  'IndustrialSeparation.index.938303-6': 'Source (brine) → Product (primary brine)',
  'IndustrialSeparation.index.938303-7':
    'This technology is widely used in industrial salt production, edible salt, medicinal salt, and other fields.',
  'IndustrialSeparation.index.938303-8': 'Source (salt lake water) → Product (lithium carbonate)',
  'IndustrialSeparation.index.938303-9':
    'In December 2021, Qinghai released the "Action Plan for Building a World Class Salt Lake Industrial Base in Qinghai Province from 2021 to 2035". The plan is to achieve a salt lake industrial output value of 10 billion yuan by 2035, and the world-class salt lake industrial base will be basically completed, forming a salt lake industrial cluster with international influence.',
  'IndustrialSeparation.index.938303-12': 'Food Processing',
  'IndustrialSeparation.index.938303-13': 'Traditional Chinese Medicine Extraction',
  'IndustrialSeparation.index.938303-14': 'Brine Refining',
  'IndustrialSeparation.index.938303-15': 'Salt Lake Lithium Extraction',
  'IndustrialSeparation.index.938303-16': 'Industrial Separation',

  'IndustrialSewage.index.241726-0': 'Wastewater Treatment Process For Printed Circuit Boards',
  'IndustrialSewage.index.241726-1': 'Source (industrial wastewater) → Product (industrial pure water)',
  'IndustrialSewage.index.241726-2':
    "According to a report released by Prismark in March 2022, the output value of China's domestic PCB industry reached $43.62 billion in 2021, a year-on-year increase of 24.6%. In 2021, the global PCB industry's output value reached 80.449 billion US dollars, a year-on-year increase of 23.4%.",
  'IndustrialSewage.index.241726-3':
    'According to the "Research on the Development Status and Market Profit Forecast Report of China\'s PCB Industry from 2020 to 2026" released by Zhiyan Consulting, the global PCB output value will exceed 75 billion US dollars in 2024, and the growth rate of domestic PCB products will be significantly higher than the global market growth rate, especially the output value of high-end boards such as high-rise boards. In the future, the proportion of China\'s PCB output value will continue to increase.',
  'IndustrialSewage.index.241726-4': 'Source (oily wastewater) → Product (degreased clear liquid)',
  'IndustrialSewage.index.241726-5':
    'Oily substances contained in oily wastewater, including natural petroleum, petroleum products, tar and its fractions, as well as edible animal and vegetable oils and fats',
  'IndustrialSewage.index.241726-6': 'Wastewater Resource Utilization Process',
  'IndustrialSewage.index.241726-8': 'Industrial Water Supply Process',
  'IndustrialSewage.index.241726-10': 'Industrial water supply: source',
  'IndustrialSewage.index.241726-11': 'tap water',
  'IndustrialSewage.index.241726-12': '→ Product',
  'IndustrialSewage.index.241726-13': 'Ultrapure water',
  'IndustrialSewage.index.241726-14': 'Industrial wastewater resource utilization: source',
  'IndustrialSewage.index.241726-15': 'Industrial Sewage',
  'IndustrialSewage.index.241726-16': 'Industrial ultra pure water',
  'IndustrialSewage.index.241726-17':
    'The "China 2050 Photovoltaic Development Outlook" (2019) jointly released by the Energy Research Institute of the National Development and Reform Commission of China, predicts that the total installed capacity of photovoltaics will reach 3 billion kilowatts by 2035, accounting for 28% of the total social electricity consumption that year. Based on this, it is estimated that the corresponding water consumption is about 2-3 billion cubic meters. By 2050, the total installed capacity of photovoltaics will reach 5 billion kilowatts.',
  'IndustrialSewage.index.241726-19':
    'On March 15, 2021, the "Standard Conditions for the Photovoltaic Manufacturing Industry (2021 Edition)" issued by the Ministry of Industry and Information Technology was officially implemented, stating that the production water consumption of photovoltaic manufacturing projects should meet the following requirements: ① The water cycle utilization rate of polycrystalline silicon projects should not be less than 95%; The water consumption of the 2 silicon wafer project is less than 1300 tons/million wafers; ③ The water consumption of the P-type crystalline silicon battery project is less than 750 tons/MWp, while the water consumption of the N-type crystalline silicon battery project is less than 90O tons/MWp.',
  'IndustrialSewage.index.241726-22': 'Source (chemical wastewater) → Product (industrial water)',
  'IndustrialSewage.index.241726-23':
    'Chemical wastewater has the characteristics of toxicity and irritation, high concentration of organic matter, unstable pH, numerous biologically difficult to degrade substances, and high hardness.',
  'IndustrialSewage.index.241726-24':
    'In a survey of industrial wastewater emissions from 41 key industries in China, the Ministry of Environmental Protection found that the textile industry emits 1.961 billion tons of wastewater annually, accounting for 10.5% of the total industrial wastewater emissions.',
  'IndustrialSewage.index.241726-25':
    'According to statistics from the China Chemical Fiber Association, the chemical fiber production in 2022 was 64.88 million tons, a slight decrease of 0.55% year-on-year, marking the first negative growth in chemical fiber production in nearly 40 years.',
  'IndustrialSewage.index.241726-26':
    'According to the National Bureau of Statistics, the operating cost of the chemical fiber manufacturing industry in 2021 was 907.59 billion yuan, a year-on-year increase of 24.8%; The sales expenses of chemical fiber manufacturing enterprises were 8.99 billion yuan, a year-on-year increase of 11.1%. Reducing costs and increasing efficiency have become the key to the survival and development of current chemical fiber enterprises.',
  'IndustrialSewage.index.241726-27': 'Industrial Recycling Process',
  'IndustrialSewage.index.241726-29': 'Industrial Zero Emission Process',
  'IndustrialSewage.index.241726-31': 'Source (industrial wastewater) → Product (industrial water)',
  'IndustrialSewage.index.241726-32':
    'According to the "Deep Analysis and Investment Prospects Research Report on the Development of China\'s Printing and Dyeing Industry (2023-2030)" released by Guanyan Report.com, the production of printing and dyeing fabrics by enterprises above designated size in China was 60.581 billion meters in 2021, a year-on-year increase of 15.39%; From January to November 2022, the production of printing and dyeing fabrics by enterprises above designated size in the printing and dyeing industry reached 50.435 billion meters, a year-on-year decrease of 7.05%. The overall production of printing and dyeing products showed a fluctuating upward trend.',
  'IndustrialSewage.index.241726-35':
    "Data shows that in 2021, the revenue of enterprises above designated size in China's printing and dyeing industry was 294.987 billion yuan, a year-on-year increase of 16.1%; From January to November 2022, the operating revenue of printing and dyeing enterprises above designated size was 282.54 billion yuan, a year-on-year increase of 5.97%. The operational pressure of printing and dyeing enterprises in China has significantly increased, and the operational efficiency of the industry needs to be improved.",
  'IndustrialSewage.index.241726-36':
    "According to relevant data, the utilization efficiency of water resources in China's printing and dyeing industry is relatively low, with a unit water consumption of 3-4 times that of foreign countries. The average content of pollutants in textile printing and dyeing wastewater is as high as 2-3 times that of foreign countries. It has the characteristics of high pollution concentration, multiple types, high alkalinity, high toxicity, and high chromaticity, and is one of the difficult to treat industrial wastewater.",
  'IndustrialSewage.index.241726-37': 'Industrial Pure Water',
  'IndustrialSewage.index.241726-38': 'Oily Wastewater',
  'IndustrialSewage.index.241726-39': 'Photovoltaic',
  'IndustrialSewage.index.241726-40': 'Chemical Industry',
  'IndustrialSewage.index.241726-41': 'Chemical Fiber',
  'IndustrialSewage.index.241726-42': 'Printing and Dyeing',

  'LivestockAndPoultryBreeding.index.941480-0': 'Drinking water process',
  'LivestockAndPoultryBreeding.index.941480-1': 'Wastewater resource utilization process',
  'LivestockAndPoultryBreeding.index.941480-2':
    'Livestock and poultry water supply: source (a certain water source) → product (livestock and poultry drinking water)',
  'LivestockAndPoultryBreeding.index.941480-3':
    'Resource utilization of livestock and poultry wastewater: source (livestock and poultry wastewater) → product (farmland irrigation/fertilization, etc.)',
  'LivestockAndPoultryBreeding.index.941480-4':
    'There are corresponding standards for the quality of drinking water for livestock and poultry, and it can only be used as drinking water if it meets certain standards.',
  'LivestockAndPoultryBreeding.index.941480-5':
    'Livestock and poultry wastewater belongs to the "three high" wastewater with high organic concentration, high N, P content, and high harmful microbial count, and is a good resource.',
  'LivestockAndPoultryBreeding.index.941480-6': 'Livestock and Poultry Breeding',

  'SeaWaterDesalination.index.114437-0': 'Reverse osmosis seawater process diagram',
  'SeaWaterDesalination.index.114437-1': 'Source (seawater) → Product (drinking water)',
  'SeaWaterDesalination.index.114437-2':
    'The Action Plan for the Development of Seawater Desalination Utilization (2021-2025) issued by the National Development and Reform Commission and the Ministry of Natural Resources states that the total scale of seawater desalination in China needs to reach over 2.9 million tons per day by 2025.',
  'SeaWaterDesalination.index.114437-4':
    'According to the National Seawater Utilization Report, as of the end of 2021, there are 144 seawater desalination projects in China, with a scale of 1.8564 million tons/day, an increase of 200000 tons/day compared to 2020. There is still huge space to reach the goal of a total seawater desalination scale of 2.9 million tons/day in 2025.',
  'SeaWaterDesalination.index.114437-5': 'Sea Water Desalination',

  'WastewaterTreatment.index.307342-0': 'Rural Domestic Sewage',
  'WastewaterTreatment.index.307342-1': 'Sewage treatment and standard discharge',
  'WastewaterTreatment.index.307342-2': 'Suitable for water environment treatment and rural domestic sewage treatment',
  'WastewaterTreatment.index.307342-3': 'Urban Domestic Sewage',
  'WastewaterTreatment.index.307342-4':
    'Improving Quality and Efficiency, Environmental Protection and Energy Conservation',
  'WastewaterTreatment.index.307342-5': 'Suitable for urban sewage treatment plants',
  'WastewaterTreatment.index.307342-6': 'Urban River Water',
  'WastewaterTreatment.index.307342-7': 'Purification treatment and standard reuse',
  'WastewaterTreatment.index.307342-8': 'Suitable for miscellaneous water such as green irrigation and firefighting',
  'WastewaterTreatment.index.307342-9': 'Water Reuse ',
  'WastewaterTreatment.index.307342-10': 'Resource regeneration and safe reuse',
  'WastewaterTreatment.index.307342-11':
    'Suitable for household miscellaneous water such as flushing, greening, car washing, and cleaning',
  'WastewaterTreatment.index.307342-12': 'Wastewater Treatment',

  'ApplicationProspect.index.637894-0': 'Application Prospect',
  'TreatmentProcess.index.902955-0': 'Treatment Process',

  'ApplicationArea.index.902956-0': 'en/applicationArea',
  'ApplicationArea.index.902956-6': '/en/applicationArea',
  'ApplicationArea.index.902956-1': '/en/applicationArea/sewage',
  'ApplicationArea.index.902956-2': '/en/applicationArea/seawater',
  'ApplicationArea.index.902956-3': '/en/applicationArea/wasteWater',
  'ApplicationArea.index.902956-4': '/en/applicationArea/separation',
  'ApplicationArea.index.902956-5': '/en/applicationArea/animalHusbandry',

  'ApplicationArea.index.902956-7': '/en/applicationArea/wasteWater/pureWater',
  'ApplicationArea.index.902956-8': '/en/applicationArea/wasteWater/oily',
  'ApplicationArea.index.902956-9': '/en/applicationArea/wasteWater/photovoltaic',
  'ApplicationArea.index.902956-10': '/en/applicationArea/wasteWater/industry',
  'ApplicationArea.index.902956-11': '/en/applicationArea/wasteWater/fiber',
  'ApplicationArea.index.902956-12': '/en/applicationArea/wasteWater/dipdye',

  'ApplicationArea.index.902956-14': '/en/applicationArea/separation/food',
  'ApplicationArea.index.902956-15': '/en/applicationArea/separation/medicine',
  'ApplicationArea.index.902956-16': '/en/applicationArea/separation/brine',
  'ApplicationArea.index.902956-17': '/en/applicationArea/separation/salt',
};
