/*
 * 页面路由
 */
import { lazy } from 'react';
import lazyLoad from '@c/components/lozyLoad';
import React from 'react';
import { Navigate } from 'react-router';
import ApplicationItem from '@m/pages/ApplicationArea/ApplicationItem';
import i18next from 'i18next';
const Index = lazyLoad(lazy(() => import(`@m/pages/Index`)));
const Contact = lazyLoad(lazy(() => import(`@m/pages/ContactUs`)));
const ContactUs = lazyLoad(lazy(() => import(`@m/pages/ContactUs/ContactUs`)));
const AboutUs = lazyLoad(lazy(() => import(`@m/pages/AboutUs`)));
const JoinUs = lazyLoad(lazy(() => import(`@m/pages/ContactUs/JoinUs`)));
const Counselling = lazyLoad(lazy(() => import(`@m/pages/ContactUs/Counselling`)));
const Product = lazyLoad(lazy(() => import(`@m/pages/Product`)));
const Componey = lazyLoad(lazy(() => import(`@m/pages/AboutUs/Componey`)));
const Strengths = lazyLoad(lazy(() => import(`@m/pages/AboutUs/Strengths`)));
const TestCenter = lazyLoad(lazy(() => import(`@m/pages/AboutUs/TestCenter`)));
const Purpose = lazyLoad(lazy(() => import(`@m/pages/AboutUs/Purpose`)));
const Qualifications = lazyLoad(lazy(() => import(`@m/pages/AboutUs/Qualifications`)));
const ApplicationLayout = lazyLoad(lazy(() => import(`@m/pages/ApplicationArea`)));
const CoreTechnology = lazyLoad(lazy(() => import(`@m/pages/CoreTechnology`)));

const ProductDetail = lazyLoad(lazy(() => import(`@m/pages/ProductDetail`)));
const NoMatch = lazyLoad(lazy(() => import(`@m/components/404`)));
const SearchConsult = lazyLoad(lazy(() => import(`@m/pages/SearchConsult`)));

const pageRouter: any = [
  {
    path: '',
    name: i18next.t('Layout.index.749881-14'),
    key: '',
    exact: true,
    element: Index,
  },
  {
    path: 'aboutus',
    name: i18next.t('Layout.index.749881-5'),
    key: i18next.t('AboutUs.index.745708-10'),
    element: AboutUs,
    children: [
      {
        path: 'introduce',
        name: i18next.t('Layout.index.749881-0'),
        key: i18next.t('AboutUs.index.745708-11'),
        element: Componey,
      },
      {
        path: 'componey',
        name: i18next.t('Layout.index.749881-1'),
        key: i18next.t('AboutUs.index.745708-12'),
        element: Strengths,
      },
      {
        path: 'testCenter',
        name: i18next.t('Layout.index.749881-2'),
        key: i18next.t('AboutUs.index.745708-13'),
        element: TestCenter,
      },
      {
        path: 'purpose',
        name: i18next.t('Layout.index.749881-3'),
        key: i18next.t('AboutUs.index.745708-14'),
        element: Purpose,
      },
      {
        path: 'qualifications',
        name: i18next.t('Layout.index.749881-4'),
        key: i18next.t('AboutUs.index.745708-15'),
        element: Qualifications,
      },
    ],
  },
  {
    path: 'product',
    name: i18next.t('Layout.index.749881-6'),
    key: i18next.t('components.ProductIntroduce.976335-9'),
    element: Product,
  },
  {
    path: 'product/detail',
    name: '产品详情',
    key: i18next.t('components.ProductIntroduce.976335-10'),
    element: ProductDetail,
    hideMenu: true,
  },
  {
    path: 'coreTechnology',
    name: i18next.t('CoreTechnology.index.486489-26'),
    key: i18next.t('CoreTechnology.index.486489-30'),
    element: CoreTechnology,
  },
  {
    path: 'applicationArea',
    name: i18next.t('Index.technology.260118-0'),
    key: i18next.t('ApplicationArea.index.902956-0'),
    element: ApplicationLayout,
    children: [
      {
        index: true,
        element: <Navigate to="drinkingWater" replace />,
      },
      {
        path: 'drinkingWater',
        name: i18next.t('DomesticWater.index.358859-6'),
        key: i18next.t('ApplicationArea.index.902956-6'),
        element: <ApplicationItem title={i18next.t('DomesticWater.index.358859-6')} />,
      },
      {
        path: 'sewage',
        name: i18next.t('WastewaterTreatment.index.307342-12'),
        key: i18next.t('ApplicationArea.index.902956-1'),
        element: <ApplicationItem title={i18next.t('WastewaterTreatment.index.307342-12')} />,
      },
      {
        path: 'seawater',
        name: i18next.t('SeaWaterDesalination.index.114437-5'),
        key: i18next.t('ApplicationArea.index.902956-2'),
        element: <ApplicationItem title={i18next.t('SeaWaterDesalination.index.114437-5')} />,
      },
      {
        path: 'wasteWater',
        name: i18next.t('IndustrialSewage.index.241726-15'),
        key: i18next.t('ApplicationArea.index.902956-3'),
        //  element: <Navigate to={i18next.t('ApplicationArea.index.902956-7')} />,
        children: [
          {
            index: true,
            element: <Navigate to="pureWater" replace />,
          },
          {
            path: 'pureWater',
            name: i18next.t('IndustrialSewage.index.241726-37'),
            key: i18next.t('ApplicationArea.index.902956-7'),
            element: <ApplicationItem title={i18next.t('IndustrialSewage.index.241726-37')} />,
            hideMenu: true,
          },
          {
            path: 'oily',
            name: i18next.t('IndustrialSewage.index.241726-38'),
            key: i18next.t('ApplicationArea.index.902956-8'),
            element: <ApplicationItem title={i18next.t('IndustrialSewage.index.241726-38')} />,
            hideMenu: true,
          },
          {
            path: 'photovoltaic',
            name: i18next.t('IndustrialSewage.index.241726-39'),
            key: i18next.t('ApplicationArea.index.902956-9'),
            element: <ApplicationItem title={i18next.t('IndustrialSewage.index.241726-39')} />,
            hideMenu: true,
          },
          {
            path: 'industry',
            name: i18next.t('IndustrialSewage.index.241726-40'),
            key: i18next.t('ApplicationArea.index.902956-10'),
            element: <ApplicationItem title={i18next.t('IndustrialSewage.index.241726-40')} />,
            hideMenu: true,
          },
          {
            path: 'fiber',
            name: i18next.t('IndustrialSewage.index.241726-41'),
            key: i18next.t('ApplicationArea.index.902956-11'),
            element: <ApplicationItem title={i18next.t('IndustrialSewage.index.241726-41')} />,
            hideMenu: true,
          },
          {
            path: 'dipdye',
            name: i18next.t('IndustrialSewage.index.241726-42'),
            key: i18next.t('ApplicationArea.index.902956-12'),
            element: <ApplicationItem title={i18next.t('IndustrialSewage.index.241726-42')} />,
            hideMenu: true,
          },
        ],
      },
      {
        path: 'separation',
        name: i18next.t('IndustrialSeparation.index.938303-16'),
        key: i18next.t('ApplicationArea.index.902956-4'),
        element: <Navigate to={i18next.t('ApplicationArea.index.902956-13')} />,
        children: [
          {
            path: 'food',
            name: i18next.t('IndustrialSeparation.index.938303-12'),
            key: i18next.t('ApplicationArea.index.902956-13'),
            element: <ApplicationItem title={i18next.t('IndustrialSeparation.index.938303-12')} />,
            hideMenu: true,
          },
          {
            path: 'medicine',
            name: i18next.t('IndustrialSeparation.index.938303-13'),
            key: i18next.t('ApplicationArea.index.902956-14'),
            element: <ApplicationItem title={i18next.t('IndustrialSeparation.index.938303-13')} />,
            hideMenu: true,
          },
          {
            path: 'brine',
            name: i18next.t('IndustrialSeparation.index.938303-14'),
            key: i18next.t('ApplicationArea.index.902956-15'),
            element: <ApplicationItem title={i18next.t('IndustrialSeparation.index.938303-14')} />,
            hideMenu: true,
          },
          {
            path: 'salt',
            name: i18next.t('IndustrialSeparation.index.938303-15'),
            key: i18next.t('ApplicationArea.index.902956-16'),
            element: <ApplicationItem title={i18next.t('IndustrialSeparation.index.938303-15')} />,
            hideMenu: true,
          },
        ],
      },

      {
        path: 'animalHusbandry',
        name: i18next.t('LivestockAndPoultryBreeding.index.941480-6'),
        key: i18next.t('ApplicationArea.index.902956-5'),
        element: <ApplicationItem title={i18next.t('LivestockAndPoultryBreeding.index.941480-6')} />,
      },
    ],
  },
  {
    path: 'contact',
    name: i18next.t('ContactUs.index.792848-0'),
    key: i18next.t('ContactUs.index.792848-3'),
    element: Contact,
    children: [
      {
        index: true,
        element: <Navigate to="contactUs" replace />,
      },
      {
        path: 'contactUs',
        name: i18next.t('ContactUs.index.792848-0'),
        key: i18next.t('ContactUs.index.792848-4'),
        element: ContactUs,
      },
      {
        path: 'joinus',
        name: i18next.t('ContactUs.index.792848-1'),
        key: i18next.t('ContactUs.index.792848-5'),
        element: JoinUs,
      },
      {
        path: 'counselling',
        name: i18next.t('ContactUs.index.792848-2'),
        key: i18next.t('ContactUs.index.792848-6'),
        element: Counselling,
      },
    ],
  },
  {
    path: 'searchConsult',
    name: '搜索结果',
    key: '/searchConsult',
    element: SearchConsult,
    hideMenu: true,
  },
  {
    path: '404',
    name: '404',
    key: '/404',
    exact: true,
    hideMenu: true,
    element: NoMatch,
  },
  {
    path: '*',
    name: '404',
    key: '/404',
    hideMenu: true,
    element: <Navigate to="/404" replace />,
  },
];

export default pageRouter;
