// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,\nbody,\n#root_PVoJOI {\n  height: 100%;\n  width: 100%;\n  margin: 0;\n  padding: 0;\n}\ndiv ul,\ndiv ol,\ndiv li {\n  list-style: none;\n  margin: 0;\n  padding: 0;\n}\ndiv p {\n  margin: 0;\n}\n:root:root {\n  --adm-color-primary: #0053cf;\n}\n", "",{"version":3,"sources":["webpack://./src/mobile/global.less"],"names":[],"mappings":"AAAA;;;EAGE,YAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;AACF;AAEA;;;EAGE,gBAAA;EACA,SAAA;EACA,UAAA;AAAF;AAEA;EACE,SAAA;AAAF;AAGA;EACE,4BAAA;AADF","sourcesContent":["html,\nbody,\n#root {\n  height: 100%;\n  width: 100%;\n  margin: 0;\n  padding: 0;\n}\n\ndiv ul,\ndiv ol,\ndiv li {\n  list-style: none;\n  margin: 0;\n  padding: 0;\n}\ndiv p {\n  margin: 0;\n}\n\n:root:root {\n  --adm-color-primary: #0053cf;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root_PVoJOI"
};
export default ___CSS_LOADER_EXPORT___;
