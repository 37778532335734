// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".spin-box_phojly {\n  height: calc(100vh - 21.333333vw);\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/common/components/Loading/index.less"],"names":[],"mappings":"AAAA;EACE,iCAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF","sourcesContent":[".spin-box {\n  height: calc(100vh - 80px);\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spin-box": "spin-box_phojly"
};
export default ___CSS_LOADER_EXPORT___;
