import index from './zh-CN/index';
import coreTechnology from './zh-CN/coreTechnology';
import applicationArea from './zh-CN/applicationArea';
import contactUs from './zh-CN/contactUs';
import aboutUs from './zh-CN/aboutUs';
import product from './zh-CN/product';

export default {
  ...index,
  ...coreTechnology,
  ...applicationArea,
  ...contactUs,
  ...aboutUs,
  ...product,

  'Layout.index.749881-0': '公司介绍',
  'Layout.index.749881-1': '企业实力',
  'Layout.index.749881-2': '检测中心',
  'Layout.index.749881-3': '企业宗旨',
  'Layout.index.749881-4': '企业资质',
  'Layout.index.749881-5': '关于我们',
  'Layout.index.749881-6': '产品中心',
  'Layout.index.749881-7': '全部产品',
  'Layout.index.749881-8': '系列产品',
  'Layout.index.749881-9': '咨询下单',
  'Layout.index.749881-10': '暂无详情数据',
  'Layout.index.749881-11': '搜索',
  'Layout.index.749881-12': '平板陶瓷膜(常规)',
  'Layout.index.749881-13': '玻纤平板陶瓷膜组件',
  'Layout.index.749881-14': '首页',
  'Index.technology.260118-0': '应用领域',

  'utils.util.037612-0': '请输入密码',
  'utils.util.037612-1': '请输入8-16个字符以内',
  'utils.util.037612-2': '密码必须包含大写、小写字母且有数字或特殊字符',
  'utils.util.037612-3': '请输入手机号',
  'utils.util.037612-4': '请输入正确格式的手机号',
};
