export default {
  'Index.index.076615-2': '运行费用节省',
  'Index.index.076615-3': '倍',
  'Index.index.076615-4': '跨膜压差',
  'Index.index.076615-5': '通量',
  'Index.index.076615-6': '使用寿命',
  'Index.index.076615-7': '高性能产品',
  'Index.index.076615-8': '30年水处理行业',
  'Index.index.076615-9': '60年陶瓷材料研发',
  'Index.index.076615-10': '30年陶瓷膜开发与应用',
  'Index.index.076615-11': '定制化服务',
  'Index.index.076615-12': '多场景应用',
  'Index.index.076615-13': '全系列膜组',
  'Index.index.076615-14': '轻松膜选型',
  'Index.index.076615-15': '智能化生产',
  'Index.index.076615-16': '1万平米专用厂房',
  'Index.index.076615-17': '年产50万平米系列规格能力',
  'Index.index.076615-18': '自动化、连续化生产线',
  'Index.index.076615-19': 'W膜--新一代无机膜 让膜分离更高效更经济',

  'Index.index.076615-50': '高强度',
  'Index.index.076615-51': '大通量',
  'Index.index.076615-52': '耐污染',
  'Index.index.076615-53': '抗氧化',

  'Index.index.076615-21': 'W膜',
  'Index.index.076615-22': 'W无机膜',
  'Index.index.076615-23': '查看详情',
  'Index.index.076615-24': '对比「有机膜」',
  'Index.index.076615-25': '对比「常规平板膜」',
  'Index.index.076615-26': '膜面积增加到',
  'Index.index.076615-27': '反洗',
  'Index.index.076615-28': '更高效、更彻底',
  'Index.index.076615-29': '纵向抗折强度高于',
  'Index.index.076615-30': '相关产品',
  'Index.index.076615-31': 'W膜组件',
  'Index.index.076615-32': 'W膜塔',
  'Index.index.076615-33': '300平米W无机膜组件',
  'Index.index.076615-34': '平板陶瓷膜',
  'Index.index.076615-35': '常规',
  'Index.index.076615-36': '新型水处理技术',
  'Index.index.076615-37': '过滤面积大',
  'Index.index.076615-38': '化学稳定性好',
  'Index.index.076615-39': '耐酸碱、耐腐蚀性',
  'Index.index.076615-40': '抗污染性强',
  'Index.index.076615-41': '关于我们',
  'Index.index.076615-42': '硅元膜 SICERMEM',
  'Index.index.076615-43':
    '上海硅苑膜科技有限公司源于1958年成立的山东省硅酸盐研究设计院，于2020年被上海市科委认定为国家级高新技术企业。',
  'Index.index.076615-44':
    '经过60多年的积淀，已发展成集W无机膜、常规平板陶瓷膜为一体，在国内外领先水平的无机膜材料研究、开发和生产基地。',
  'Index.index.076615-45':
    '上海硅苑膜通过与丹麦奥尔堡大学、同济大学等国内外著名高校、研究机构、以及大型水务集团的合作，全面提升了陶瓷膜的核心技术，实现了可持续发展，可为合作伙伴提供定制化的无机膜产品。',
  'Index.index.076615-46': '查看更多',
  'Index.index.076615-47': '我们的优势',
  'Index.index.076615-48':
    '经过60多年的积淀，已发展成集W无机膜、常规平板陶瓷膜为一体，在国内外领先水平的无机膜材料研究、开发和生产基地。',

  'Index.index.076615-49': '/searchConsult',
  'Index.index.076615-54': '平板陶瓷膜(常规)',
};
