/*
 * 移动端入口文件
 */
import React from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';

import { ConfigProvider } from 'antd-mobile';
import zhCN from 'antd-mobile/es/locales/zh-CN';
import 'antd-mobile/es/global';

import '@m/global.less';
import mobileRouter from '@m/router';

const AppRoutes = () => useRoutes(mobileRouter);
const App: React.FC = () => {
  return (
    <ConfigProvider locale={zhCN}>
      <Router>
        <AppRoutes />
      </Router>
    </ConfigProvider>
  );
};
export default App;
