export default {
  'DomesticWater.index.358859-0': '水厂制水',
  'DomesticWater.index.358859-1': '二次供水',
  'DomesticWater.index.358859-2': '深度处理·水质达标',
  'DomesticWater.index.358859-3': '适用于水厂新建、升级、改造',
  'DomesticWater.index.358859-4': '超滤净化·品质优饮',
  'DomesticWater.index.358859-5': '适用于新建/老旧的住宅、园区',
  'DomesticWater.index.358859-6': '生活用水',

  'IndustrialSeparation.index.938303-0': '源头（料液）→ 产物（产品）',
  'IndustrialSeparation.index.938303-1':
    '高品质酱油生产过程中，传统过滤方式普遍具有澄清度不高、过滤后还需高温灭菌、易返混等缺点，使用膜过滤不但可以有效去除微生物，还可以最大限度的保留酱香风味，过滤后的酱油长时间存放也不会出现沉淀。',
  'IndustrialSeparation.index.938303-2':
    '使用陶瓷膜技术过滤醋、料酒，能彻底澄清米醋和料酒产品，并能明显缓解陈醋货架期内沉淀问题，有效的去除醋、料酒中的微生物，提高产品品质，简化工艺。独有的错流过滤方式以及无机膜的材料特性，使之与传统过滤方式对比，具有过滤精度高、过滤过程零添加、不影响产品风味、滤芯符合食品安全、节能等优点。',
  'IndustrialSeparation.index.938303-3': '源头（料液）→ 产物（某中药）',
  'IndustrialSeparation.index.938303-4':
    '中药复杂的化学成分导致中药生产过程中提取和分离工艺繁杂、分离效率低、成本高、环境污染严重以及劳动强度大等。',
  'IndustrialSeparation.index.938303-6': '源头（卤水）→ 产物（一次盐水）',
  'IndustrialSeparation.index.938303-7': '该技术广泛应用于工业制盐、食用盐、药用盐等领域。',
  'IndustrialSeparation.index.938303-8': '源头（盐湖水）→ 产物（碳酸锂）',
  'IndustrialSeparation.index.938303-9':
    '2021年12月，青海发布《青海省建设世界级盐湖产业基地行动方案2021-2035年》，计划到2035年盐湖产业产值达到100亿元，世界级盐湖产业基地基本建成，形成具有国际影响力的盐湖产业集群。',
  'IndustrialSeparation.index.938303-12': '食品加工',
  'IndustrialSeparation.index.938303-13': '中药提取',
  'IndustrialSeparation.index.938303-14': '盐水精制',
  'IndustrialSeparation.index.938303-15': '盐湖提锂',
  'IndustrialSeparation.index.938303-16': '工业分离',

  'IndustrialSewage.index.241726-0': '印刷线路板废水处理工艺',
  'IndustrialSewage.index.241726-1': '源头（工业废水）→ 产物（工业纯水）',
  'IndustrialSewage.index.241726-2':
    'Prismark 在 2022 年 3 月发布的报告，2021年中国内 PCB 行业产值达到 436.2 亿美元，同比增长 24.6% 。2021年全球 PCB 行业产值达到 804.49 亿美元，同比增长 23.4%。',
  'IndustrialSewage.index.241726-3':
    '智研咨询发布的《2020- 2026年中国PCB行业发展现状调研及市场盈利预测报告》数据，2024年全球PCB产值将超越750亿美元，国内PCB产品增速将明显高于全球市场增速，尤其是高层板等高端板的产值增长，未来中国PCB产值占比将不断提升。',
  'IndustrialSewage.index.241726-4': '源头（含油废水）→ 产物（去油清液）',
  'IndustrialSewage.index.241726-5':
    '含油废水中所含的油类物质，包括天然石油、石油产品、焦油及其分馏物，以及食用动植物油和脂肪类',
  'IndustrialSewage.index.241726-6': '废水资源化工艺',
  'IndustrialSewage.index.241726-8': '工业给水工艺',
  'IndustrialSewage.index.241726-10': '工业给水：源头',
  'IndustrialSewage.index.241726-11': '自来水',
  'IndustrialSewage.index.241726-12': '→产物',
  'IndustrialSewage.index.241726-13': '超纯水',
  'IndustrialSewage.index.241726-14': '工业废水资源化：源头',
  'IndustrialSewage.index.241726-15': '工业废水',
  'IndustrialSewage.index.241726-16': '工业超纯水',
  'IndustrialSewage.index.241726-17':
    '中国国家发展和改革委员会能源研究所等联合发布的《中国2050光伏发展展望》(2019),2035年光伏总装机规模将达到30亿千瓦，占当年全社会用电量28%，据此估算对应耗水量约为20-30亿立方米。2050年，光伏总装机规模将达到50亿千瓦。',
  'IndustrialSewage.index.241726-19':
    '2021年3月15日，工信部发布的《光伏制造行业规范条件(2021年本)》正式施行，指出光伏制造项目生产水耗应满足以下要求:①多晶硅项目水循环利用率不低于95%;2硅片项目水耗低于1300吨/百万片;③P型晶硅电池项目水耗低于750吨/MWp，N型晶硅电池项目水耗低于90O吨/MWp。',
  'IndustrialSewage.index.241726-22': '源头（化工废水）→产物（工业用水）',
  'IndustrialSewage.index.241726-23':
    '化工废水具有有毒性和刺激性、有机物浓度高、PH不稳定、生物难降解物质多、硬度较高等特点。',
  'IndustrialSewage.index.241726-24':
    '环保部在对我国41个重点行业工业废水排放量的 调查中发现，纺织业每年废水排放量达19.61亿吨，占总工业废水排放量的10.5%。',
  'IndustrialSewage.index.241726-25':
    '中国化纤协会统计，2022 年化纤产量为6488万吨，同比微减0.55%，是近四十年来化纤产量首次出现负增长。',
  'IndustrialSewage.index.241726-26':
    '国家统计局数据，2021年化学纤维制造业营业成本为9075.9亿元 ，同比增长24.8%；化学纤维制造业企业销售费用为89.9亿元，同比增长11.1%，降本增效成为当前化纤企业生存和发展的关键。',
  'IndustrialSewage.index.241726-27': '工业回用工艺',
  'IndustrialSewage.index.241726-29': '工业零排放工艺',
  'IndustrialSewage.index.241726-31': '源头（工业废水）→产物（工业用水）',
  'IndustrialSewage.index.241726-32':
    '据观研报告网发布的《中国印染行业发展深度分析与投资前景研究报告(2023-2030年)》显示, 2021年我国规模以上企业印染布产量为605.81亿米,同比增长15.39%;2022年1-11月印染行业规模以上企业印染布产量504.35亿米，同比减少7.05%，印染产品产量整体呈波动上升的趋势。',
  'IndustrialSewage.index.241726-35':
    '数据显示，2021年我国印染行业规模以上企业营收为2949.87亿元，同比增长16.1%;2022年1-11月规模以上印染企业营业收入2825.40亿元，同比增长5.97%。我国印染企业经营压力明显加大，行业运行效益有待改善。',
  'IndustrialSewage.index.241726-36':
    '据相关数据显示，我国印染工业水资源利用效率较低，单位用水量是国外的3-4倍，而纺织印染废水中污染物平均含量高达国外的2-3倍，具有污染浓度高、种类多、碱性大、毒害大及色度高等特点，属于难处理的工业废水之一。',
  'IndustrialSewage.index.241726-37': '工业纯水',
  'IndustrialSewage.index.241726-38': '含油废水',
  'IndustrialSewage.index.241726-39': '光伏',
  'IndustrialSewage.index.241726-40': '化工',
  'IndustrialSewage.index.241726-41': '化纤',
  'IndustrialSewage.index.241726-42': '印染',

  'LivestockAndPoultryBreeding.index.941480-0': '饮用水工艺',
  'LivestockAndPoultryBreeding.index.941480-1': '废水资源化工艺',
  'LivestockAndPoultryBreeding.index.941480-2': '畜禽给水：源头（某水源）→ 产物（畜禽饮用水）',
  'LivestockAndPoultryBreeding.index.941480-3': '畜禽废水资源化：源头（畜禽废水）→ 产物（农田灌溉/施肥等）',
  'LivestockAndPoultryBreeding.index.941480-4': '畜禽饮用水水质有其相应标准，达到一定标准方可作为饮用水水质。',
  'LivestockAndPoultryBreeding.index.941480-5':
    '畜禽废水属于高有机物浓度、高N、P含量和高有害微生物数量的“三高”废水，是一种很好的资源。',
  'LivestockAndPoultryBreeding.index.941480-6': '畜禽养殖',

  'SeaWaterDesalination.index.114437-0': '反渗透海水工艺图',
  'SeaWaterDesalination.index.114437-1': '源头（海水）→ 产物（饮用水）',
  'SeaWaterDesalination.index.114437-2':
    '国家发展改革委、自然资源部印发的《海水淡化利用发展行动计划(2021-2025)》，到2025年全国海水淡化总规模需达到290万吨/日以上。',
  'SeaWaterDesalination.index.114437-4':
    '《全国海水利用报告》数据显示，截至2021年底，全国现有海水淡化工程144个，工程规模185.64万吨/日，比2020年新增20万吨/日，距离2025年全国海水淡化总规模290万吨/日的目标仍有巨大空间。',
  'SeaWaterDesalination.index.114437-5': '海水淡化',

  'WastewaterTreatment.index.307342-0': '农村生活污水',
  'WastewaterTreatment.index.307342-1': '污水治理·达标排放',
  'WastewaterTreatment.index.307342-2': '适用于水环境治理、农村生活污水治理',
  'WastewaterTreatment.index.307342-3': '城市生活污水',
  'WastewaterTreatment.index.307342-4': '提质增效·环保节能',
  'WastewaterTreatment.index.307342-5': '适用于城镇污水处理厂',
  'WastewaterTreatment.index.307342-6': '城镇河道水',
  'WastewaterTreatment.index.307342-7': '净化处理·达标回用',
  'WastewaterTreatment.index.307342-8': '适用于绿化浇灌、消防等杂用水',
  'WastewaterTreatment.index.307342-9': '中水回用',
  'WastewaterTreatment.index.307342-10': '资源再生·安全回用',
  'WastewaterTreatment.index.307342-11': '适用于冲厕、绿化、洗车、扫除等生活杂用水',
  'WastewaterTreatment.index.307342-12': '污水处理',

  'ApplicationProspect.index.637894-0': '应用前景',
  'TreatmentProcess.index.902955-0': '处理工艺',

  'ApplicationArea.index.902956-0': 'applicationArea',
  'ApplicationArea.index.902956-6': '/applicationArea',
  'ApplicationArea.index.902956-1': '/applicationArea/sewage',
  'ApplicationArea.index.902956-2': '/applicationArea/seawater',
  'ApplicationArea.index.902956-3': '/applicationArea/wasteWater',
  'ApplicationArea.index.902956-4': '/applicationArea/separation',
  'ApplicationArea.index.902956-5': '/applicationArea/animalHusbandry',

  'ApplicationArea.index.902956-7': '/applicationArea/wasteWater/pureWater',
  'ApplicationArea.index.902956-8': '/applicationArea/wasteWater/oily',
  'ApplicationArea.index.902956-9': '/applicationArea/wasteWater/photovoltaic',
  'ApplicationArea.index.902956-10': '/applicationArea/wasteWater/industry',
  'ApplicationArea.index.902956-11': '/applicationArea/wasteWater/fiber',
  'ApplicationArea.index.902956-12': '/applicationArea/wasteWater/dipdye',

  'ApplicationArea.index.902956-14': '/applicationArea/separation/food',
  'ApplicationArea.index.902956-15': '/applicationArea/separation/medicine',
  'ApplicationArea.index.902956-16': '/applicationArea/separation/brine',
  'ApplicationArea.index.902956-17': '/applicationArea/separation/salt',
};
