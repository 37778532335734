export default {
  'ContactUs.index.101073-0': '上海硅苑膜科技有限公司',
  'ContactUs.index.101073-1': '电话：',
  'ContactUs.index.101073-2': '邮箱：',
  'ContactUs.index.101073-3': '地址：',
  'ContactUs.index.101073-4': '上海市青浦区嘉松中路5888号5A层',

  'Counselling.index.267509-0': '提交成功',
  'Counselling.index.267509-1': '在线询单',
  'Counselling.index.267509-2': '请在下方留言，我们将尽快与您联系',
  'Counselling.index.267509-3': '联系人',
  'Counselling.index.267509-4': '公司名称',
  'Counselling.index.267509-5': '手机',
  'Counselling.index.267509-6': '电话',
  'Counselling.index.267509-7': '请输入正确格式的固定电话',
  'Counselling.index.267509-8': '传真',
  'Counselling.index.267509-9': '请输入正确格式的传真电话',
  'Counselling.index.267509-10': '电子邮件',
  'Counselling.index.267509-11': '请输入正确格式的电子邮件地址',
  'Counselling.index.267509-12': '产品名称',
  'Counselling.index.267509-13': '数量',
  'Counselling.index.267509-14': '包装要求',
  'Counselling.index.267509-15': '其他说明',
  'Counselling.index.267509-16': '提交',

  'JoinUs.index.379095-0': '名',
  'JoinUs.index.379095-1': '岗位职责',
  'JoinUs.index.379095-2': '任职要求',

  'JoinUs.index.022321-0': '工艺工程师',
  'JoinUs.index.022321-1':
    '1、制定项目设计进度计划，负责完成设备选型、设计方案和绘制设计图纸和施工图纸。2、完成技术交底、设计变更、项目施工、竣工资料等所有项目实施中的技术工作。',
  'JoinUs.index.022321-2':
    '1、环境工程或给排水专业、2年以上工作经验。2、有污水处理工程、市政给水工程的设计经验，了解污水处理技术和膜技术在污水处理领域的应用。3、熟练使用CAD制图和办公软件。',
  'JoinUs.index.022321-3': '平板膜销售工程师',
  'JoinUs.index.022321-4':
    '1、收集并分析市场信息，及时反馈客户信息，提出有效的市场运作及销售建议。2、根据公司市场战略，积极开拓新市场，维护客户关系，扩大业务量。3、了解、分析、反馈市场竞争情况。',
  'JoinUs.index.022321-5':
    '1、负责平板陶瓷膜产品、废水处理工程的销售。2、有中水回用，废水处理行业销售经验为佳，熟悉水处理用膜或MBR膜者优先考虑。3、工程公司以及相关经销合作伙伴的渠道开拓，维护。4、良好的沟通、协调能力，表达能力强，突出的执行能力。5、良好的职业素养和敬业精神。',
  'JoinUs.index.022321-6': '陶瓷膜销售工程师',
  'JoinUs.index.022321-7':
    '1、收集并分析市场信息，及时反馈客户信息，提出有效的市场运作及销售建议。2、根据公司市场战略，积极开拓新市场和新客户，维护客户关系，扩大业务量。3、了解、分析、反馈市场竞争情况，协助展开市场调查同类产品信息。',
  'JoinUs.index.022321-8':
    '1、负责管式陶瓷膜、组件及设备的销售。2、有陶瓷膜销售经验为佳，熟悉陶瓷膜应用者优先考虑。3、善于掌握市场信息，具有敏锐的市场观察力、判断力。4、良好的沟通、协调能力，表达能力强，突出的执行能力。5、能够独立组织协调工作，具有良好的职业素养。',

  'ContactUs.index.792848-0': '联系我们',
  'ContactUs.index.792848-1': '加入我们',
  'ContactUs.index.792848-2': '在线咨询',

  'ContactUs.index.792848-3': 'contact',
  'ContactUs.index.792848-4': '/contact',
  'ContactUs.index.792848-5': '/contact/joinus',
  'ContactUs.index.792848-6': '/contact/counselling',
};
