import './App.less';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { EventEmitter } from 'events';

import PCAPP from './pc/index';
import MOBILEAPP from './mobile/index';
import ErrorBoundary from './common/components/Error';
import stores from './pc/store';
import { isMobile } from './common/utils/util';
import 'antd/dist/antd.less';

const ErrorComponent = ErrorBoundary;
const Children = isMobile ? MOBILEAPP : PCAPP;
// import "./i18n";

function App() {
  window.share = {
    event: new EventEmitter(),
  };
  window.share.event.setMaxListeners(50);

  return (
    <ErrorComponent>
      <Provider {...stores}>
          <Children />
      </Provider>
    </ErrorComponent>
  );
}

export default App;
